import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/noahgilmore.com/noahgilmore.com/src/components/BlogPageLayout.jsx";
import CaptionedImage from '../../components/CaptionedImage';
import Instabug from '../../components/Instabug';
import FlowGrid from '../../components/FlowGrid';
import IOS13SystemColorTable from '../../components/IOS13SystemColorTable';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <blockquote>
      <p parentName="blockquote">{`Update (6/13/2019): The original version of this post featured the wrong colors in the ColorCompatibility enum at the end of the post. This has now been fixed.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Update (9/21/2019): The original version of this post included systemBrown, a color which was removed in the Xcode 11 GM. It also contained unnecessary availability checks in the code at the bottom (system colors like red, blue etc have been available since iOS 7). The code has been updated, and the tables in this post still list all system and element colors - even those which have been available for a while, since they're different in dark mode vs light mode. I also updated a reference to Mac Catalyst.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Update (10/5/2019): A previous version of this post included the wrong default colors in the `}<inlineCode parentName="p">{`ColorCompatibility`}</inlineCode>{` code at the bottom of the post. These have been updated - the defaults are now the light mode colors which are being used in production in `}<a parentName="p" {...{
          "href": "https://itunes.apple.com/us/app/trestle-the-new-sudoku/id1300230302?mt=8"
        }}>{`Trestle`}</a>{` and `}<a parentName="p" {...{
          "href": "https://itunes.apple.com/us/app/cifilter-io/id1457458557?mt=8"
        }}>{`CIFilter.io`}</a>{`.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Update (10/5/2019): In order to better support bug reporting on ColorCompatibility, I've released it as a library. The code now lives in `}<a parentName="p" {...{
          "href": "https://github.com/noahsark769/ColorCompatibility"
        }}>{`this GitHub repo`}</a>{`. You can read more about it `}<a parentName="p" {...{
          "href": "/blog/color-compatibility-pod"
        }}>{`here`}</a>{`.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Update (10/17/2019): A previous version of this post included the wrong color values in one of the example code blocks. This has now been fixed, but I'd recommend looking at the `}<a parentName="p" {...{
          "href": "https://github.com/noahsark769/ColorCompatibility"
        }}>{`ColorCompatibility library`}</a>{` if you're interested in using it in your own projects.`}</p>
    </blockquote>
    <p>{`At WWDC 2019, Apple announced that Dark Mode would be supported on iOS 13. There are some significant changes to UIKit in order to support this - many of them are detailed in the talk `}<a parentName="p" {...{
        "href": "https://developer.apple.com/videos/play/wwdc2019/214/"
      }}>{`Implementing Dark Mode on iOS`}</a>{` which I'd highly recommend watching.`}</p>
    <p>{`One of the changes that makes adopting Dark Mode so easy is the new system colors API from `}<inlineCode parentName="p">{`UIColor`}</inlineCode>{`. On iOS 12 and older, you might have a label you want to make black, and it would work just fine - in fact, black was the default color for `}<inlineCode parentName="p">{`UILabel`}</inlineCode>{`s.`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`label.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`textColor`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = UIColor.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`black`}</span></span></code></pre>
    <p>{`But in Dark Mode, the background will also be black, which means the text won't be visible. In iOS 13+, it's better to use the new system color which will respect the user's color scheme preference:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`label.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`textColor`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = UIColor.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`label`}</span></span></code></pre>
    <p><inlineCode parentName="p">{`label`}</inlineCode>{` is only one example: there are 24 new color scheme agnostic `}<inlineCode parentName="p">{`UIColor`}</inlineCode>{`s available in iOS 13+.`}</p>
    <p>{`Colors are organized into two groups:`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://developer.apple.com/documentation/uikit/uicolor/ui_element_colors"
        }}>{`Element Colors`}</a>{` (e.g. `}<inlineCode parentName="li">{`label`}</inlineCode>{`)`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://developer.apple.com/documentation/uikit/uicolor/standard_colors"
        }}>{`Standard Colors`}</a>{` (`}<inlineCode parentName="li">{`systemIndigo`}</inlineCode>{`, `}<inlineCode parentName="li">{`systemGray3`}</inlineCode>{`, etc).`}</li>
    </ol>
    <p>{`iOS 13 has 23 new element colors (`}<inlineCode parentName="p">{`label`}</inlineCode>{` etc) and one new standard color (`}<inlineCode parentName="p">{`systemIndigo`}</inlineCode>{`). However, even system colors that have been around for a while (like `}<inlineCode parentName="p">{`systemRed`}</inlineCode>{`) have become dynamic in iOS 13 - they might actually be different colors in dark mode vs light mode.`}</p>
    <p>{`(Skip to the bottom if you'd like to see a list of the new colors.)`}</p>
    <h2 {...{
      "id": "compatibility"
    }}>{`Compatibility`}</h2>
    <p>{`These new colors are all well and good, but most of us with existing apps will still be supporting devices with iOS 12 or lower, at least for a while. This means we'll probably be doing a lot of things like this, using `}<a parentName="p" {...{
        "href": "https://docs.swift.org/swift-book/ReferenceManual/Attributes.html"
      }}>{`Swift's #available syntax`}</a>{`:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk15"
          }}>{`if`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`#available`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`iOS`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`13`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`*`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`) {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    label.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`textColor`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = .`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`label`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`} `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`else`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    label.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`textColor`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = .`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`black`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`It's a workable solution, but it necessitates changing a lot of code - an `}<inlineCode parentName="p">{`if`}</inlineCode>{` statement for every custom label or background color! For `}<a parentName="p" {...{
        "href": "/blog/cifilterio"
      }}>{`CIFilter.io`}</a>{`, I wondered if there was a better way. What if, instead of the `}<inlineCode parentName="p">{`if #available`}</inlineCode>{`, there was a way to abstract the color choice down one level, so we could do something like this?`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`label.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`textColor`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = ColorCompatibility.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`label`}</span></span></code></pre>
    <h2 {...{
      "id": "generating-system-colors"
    }}>{`Generating system colors`}</h2>
    <p>{`I wrote a `}<a parentName="p" {...{
        "href": "https://github.com/noahsark769/NGSystemColorComparison"
      }}>{`small app`}</a>{` (with SwiftUI, no less!) which displays all the system colors in the current color scheme.`}</p>
    <FlowGrid columns={2} mdxType="FlowGrid">
    <CaptionedImage max={300} filename="systemcolors-light.png" alt="System colors (light mode)" caption="Light mode" mdxType="CaptionedImage" />
    <CaptionedImage max={300} filename="systemcolors-dark.png" alt="System colors (dark mode)" caption="Dark mode" mdxType="CaptionedImage" />
    </FlowGrid>
    <p>{`This app collects all the `}<inlineCode parentName="p">{`UIColor`}</inlineCode>{` objects - once we have those, we can use their red/green/blue/alpha components to `}<a parentName="p" {...{
        "href": "https://github.com/noahsark769/NGSystemColorComparison/blob/master/NGSystemColorComparison/SystemColors.swift#L102"
      }}>{`generate the implementation`}</a>{` of `}<inlineCode parentName="p">{`ColorCompatibility`}</inlineCode>{` that we want:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`enum`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`ColorCompatibility`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`static`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`var`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` label: UIColor {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`if`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`#available`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`iOS`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`13`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`*`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`) {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`return`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` .`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`label`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`return`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`UIColor`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`red`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`0.0`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`green`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`0.0`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`blue`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`0.0`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`alpha`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`1.0`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`static`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`var`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` secondaryLabel: UIColor {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`if`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`#available`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`iOS`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`13`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`*`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`) {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`return`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` .`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`secondaryLabel`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`return`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`UIColor`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`red`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`0.23529411764705882`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`green`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`0.23529411764705882`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`blue`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`0.2627450980392157`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`alpha`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`0.6`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`// ... 21 more definitions: full code in the link at the bottom`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`We can then use `}<inlineCode parentName="p">{`ColorCompatibility`}</inlineCode>{` it to set any colors we need.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Note: we can't use `}<inlineCode parentName="p">{`@available`}</inlineCode>{` for these checks, since it doesn't provide a way to check if the current environment is `}<em parentName="p">{`less`}</em>{` than a specific app version.`}</p>
    </blockquote>
    <h2 {...{
      "id": "conclusion"
    }}>{`Conclusion`}</h2>
    <p>{`This approach has some great advantages:`}</p>
    <ol>
      <li parentName="ol">{`This code will compile on iOS 13+, iOS 12 and earlier, and Catalyst ✅`}</li>
      <li parentName="ol">{`Since everything is a computed `}<inlineCode parentName="li">{`var`}</inlineCode>{`, we never pre-store colors, which means that when the user switches color scheme, our app will automatically adapt as the trait collection changes 👍`}</li>
      <li parentName="ol">{`When we drop iOS 12 support, cleaning this up will be as simple as replacing every instance of `}<inlineCode parentName="li">{`ColorCompatibility`}</inlineCode>{` with `}<inlineCode parentName="li">{`UIColor`}</inlineCode>{` 🎉`}</li>
    </ol>
    <p>{`Hopefully this makes your app's transition to dark mode easier!`}</p>
    <p>{`If you're interested in dark mode and new UIColor apis, you can `}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://twitter.com/noahsark769"
        }}>{`follow me on Twitter`}</a></strong>{` as I continue to play around with these new iOS technologies.`}</p>
    <h2 {...{
      "id": "table-of-system-colors"
    }}>{`Table of system colors`}</h2>
    <p>{`For those interested in iOS 13+ system colors but not wanting (or not able) to compile the sample app, here's a list of the system colors in light and dark mode, with their hex codes and RGBA values:`}</p>
    <IOS13SystemColorTable mdxType="IOS13SystemColorTable" />
    <h2 {...{
      "id": "colorcompatibility-full-code"
    }}>{`ColorCompatibility full code`}</h2>
    <p>{`The full, generated implementation of `}<inlineCode parentName="p">{`ColorCompatibility`}</inlineCode>{`, which is used by `}<a parentName="p" {...{
        "href": "https://itunes.apple.com/us/app/trestle-the-new-sudoku/id1300230302?mt=8"
      }}>{`Trestle`}</a>{` and `}<a parentName="p" {...{
        "href": "https://itunes.apple.com/us/app/cifilter-io/id1457458557?mt=8"
      }}>{`CIFilter.io`}</a>{`, is available `}<a parentName="p" {...{
        "href": "https://github.com/noahsark769/ColorCompatibility"
      }}>{`here`}</a>{`.`}</p>

    <style {...{
      "className": "vscode-highlight-styles"
    }}>{`
  
  .dark-default-dark {
background-color: #1E1E1E;
color: #D4D4D4;
}

.dark-default-dark .mtk1 { color: #D4D4D4; }
.dark-default-dark .mtk2 { color: #1E1E1E; }
.dark-default-dark .mtk3 { color: #6A9955; }
.dark-default-dark .mtk4 { color: #569CD6; }
.dark-default-dark .mtk5 { color: #D16969; }
.dark-default-dark .mtk6 { color: #D7BA7D; }
.dark-default-dark .mtk7 { color: #B5CEA8; }
.dark-default-dark .mtk8 { color: #CE9178; }
.dark-default-dark .mtk9 { color: #646695; }
.dark-default-dark .mtk10 { color: #4EC9B0; }
.dark-default-dark .mtk11 { color: #DCDCAA; }
.dark-default-dark .mtk12 { color: #9CDCFE; }
.dark-default-dark .mtk13 { color: #000080; }
.dark-default-dark .mtk14 { color: #F44747; }
.dark-default-dark .mtk15 { color: #C586C0; }
.dark-default-dark .mtk16 { color: #6796E6; }
.dark-default-dark .mtk17 { color: #808080; }
.dark-default-dark .mtki { font-style: italic; }
.dark-default-dark .mtkb { font-weight: bold; }
.dark-default-dark .mtku { text-decoration: underline; text-underline-position: under; }
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      